import PropTypes from 'prop-types';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState
} from 'react';
import { useDebouncedCallback } from 'use-debounce';


const Input = forwardRef((props, ref) => {
  let textInput = useRef(null);
  const INPUT_DELAY = 200;
  const [value, setValue] = useState('');
  const [innerValue, setInnerValue] = useState('');
  const {
    style,
    autoComplete,
    placeholder,
    name,
    id,
    type,
    disabled,
    mode,
    updateValue,
    isNotDebounced = false,
    multiLine,
    Styled,
    inputRef,
    onClick,
    maxLength = null,
    onLoadFocus
  } = props;

  React.useEffect(() => {
    if (isNotDebounced) {
      return;
    }
    if (props.value) {
      setInnerValue(props.value);
    } else {
      setInnerValue('');
    }
  }, [props.value]);

  const debouncedHandleOnChange = useDebouncedCallback(
    (event) => {
      if (props.handleOnChange) {
        props.handleOnChange && props.handleOnChange(innerValue, event);
      }
    },
    INPUT_DELAY
  );

  useImperativeHandle(ref, () => ({
    // clearInputField() {
    //   setTimeout(() => {
    //     textInput.current.value = '';
    //   }, 100);
    //   setValue('');
    // }
  }));

  const handleOnChange = React.useCallback((event) => {
    if (isNotDebounced) {
      const value = updateValue ? updateValue(event.target.value) : event.target.value;
      props.handleOnChange && props.handleOnChange(value, event);
      return;
    }
    event.persist();

    const newValue = updateValue ? updateValue(event.currentTarget.value) : event.currentTarget.value;;
    setInnerValue(newValue);
    debouncedHandleOnChange(event);
  }, []);
  
  const handleOnBlur = (e) => {
    props.onBlur && e?.currentTarget && props.onBlur(e.currentTarget.value);
  };
  return (
    <React.Fragment>
      <Styled.MainWrapper
        width={props.width}
        height={props.height}
        multiLine={props.multiLine}
        mode={mode}
      >
        {multiLine ? (
          <Styled.TextAreaWrapper
            type={type}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            id={id || id}
            name={name ? name : 'text_input'}
            style={style}
            value={isNotDebounced ? (props.value ? props.value : value) : innerValue}
            disableUnderline={true}
            rows={2}
            // inputref={textInput}
            disabled={disabled ? disabled : false}
            mode={mode}
            autoFocus={props.onLoadFocus}
            placeholder={placeholder || ''}
            onClick={(e)=>onClick && onClick(e) }
          />
        ) : 
          inputRef ? 
          <Styled.InputWrapper
            ref={inputRef}
            type={type}
            accept="image/png, image/jpeg"
            id={id || id}
            name={name ? name : 'text_input'}
            style={style}
            placeholder={placeholder}
            autoComplete={autoComplete || 'off'}
            disableUnderline={true}
            rows={2}
            // inputref={textInput}
            disabled={disabled ? disabled : false}
            mode={mode}
            autoFocus={props.onLoadFocus}
            {...maxLength ? {
              inputProps: { maxLength },
            } : {}}
            onChange={handleOnChange}
          /> :
          <Styled.InputWrapper
            type={type}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            accept="image/png, image/jpeg"
            id={id || id}
            name={name ? name : 'text_input'}
            style={style}
            placeholder={placeholder}
            value={isNotDebounced ? (props.value ? props.value : value) : innerValue}
            autoComplete={autoComplete || 'off'}
            disableUnderline={true}
            {...maxLength ? {
              inputProps: { maxLength },
            } : {}}
            rows={2}
            // inputref={textInput}
            disabled={disabled ? disabled : false}
            mode={mode}
            autoFocus={props.onLoadFocus}
            onClick={(e)=>onClick && onClick(e) }
          />
        }
      </Styled.MainWrapper>
    </React.Fragment>
  );
});

Input.propTypes = {
  type: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object
};

Input.defaultProps = {
  type: '',
  onChange: () => {}
};
export default Input;
