import { emailRegExp } from '@/utils/constants';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { hasValue } from '../../../utils/utils';
import RenderComponent from './RenderComponent';

const MAX_60_CHAR = 60;

const layout = window?.$environment?.CURRENT_LAYOUT;
const Message = lazy(() => import(`@/components/UI/Message/${layout}`));
const Form = lazy(() => import(`@/components/UI/Form/${layout}`));

const DotAudit = ({ apiStatus, sendFmcsa, callback, drivers, Styled, canada }) => {
  const { i18n } = useTranslation();

  const apiId = 'dotAudit';
  const hasApiStatus = hasValue(apiStatus.status, 'api', apiId);
  const days_valid = canada ? 14 : 7; 
  return (
    <Styled.Wrapper className="formSection">
      <Form
        type="server"
        direction="row"
        canadaForm={canada}
        initialValues={{
          driverId: '',
          startDate: '',
          endDate: '',
          outputFileComment: ''
        }}
        RenderComponent={RenderComponent}
        handleOnSubmit={(fields) => {
          sendFmcsa({
            data: { ...fields, driverId: fields.driverId.value },
            apiId,
            callback: callback
          });
        }}
        customProps={{ hasApiStatus, drivers, Styled }}
        validationSchema={Yup.object().shape({
          ...(canada ? {email: Yup.string().matches(emailRegExp, i18n.t('common.emailValidationMessage')).required(i18n.t('This field is required')),} : {}),
          driverId: Yup.string().nullable().required(i18n.t('This field is required')),
          startDate: Yup.date().nullable().required(i18n.t('This field is required')).when('endDate', (endDate, schema) => {
            return schema.test({
              test: (startDate) => {
                return moment(endDate).diff(moment(startDate), 'day') > (days_valid - 1);
              },
              message: `The date range must be at least ${days_valid + 1} days to comply with DOT reporting guidelines`
            })
          }),
          endDate: Yup.date().nullable().required(i18n.t('This field is required')),
          ...(canada ? {
            outputFileComment: Yup.string().max(MAX_60_CHAR, i18n.t('common.max60Char'))
          } : {outputFileComment: Yup.string().max(MAX_60_CHAR, i18n.t('common.max60Char')).required(i18n.t('This field is required'))})
        })}
      />

      {!canada && <Message type="info" message={'FDCSA info'}></Message>}
    </Styled.Wrapper>
  );
};

DotAudit.propTypes = {
  apiStatus: PropTypes.object.isRequired,
  sendFmcsa: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired
};

export default DotAudit;
